import React from "react"
import AutoReplacement from "./inputs/AutoReplacement"
import CollisionDeductible from "./inputs/CollisionDeductible"
import ComprehensiveDeductible from "./inputs/ComprehensiveDeductible"
import RoadsideCoverage from "./inputs/RoadsideCoverage"
import TransportationExpense from "./inputs/TransportationExpense"
import { Formik, Form } from "formik"
import { useAutoContext } from "../../context/auto_context"

const FormFour = () => {
  const { vehicles, data, handlePrevStep, handleNextStep } = useAutoContext()

  return (
    <Formik initialValues={vehicles} onSubmit={handleNextStep}>
      {({ values }) => (
        <Form>
          <div className="coverages">
            {
              // vehicle coverages
              vehicles.map((vehicle, index) => {
                return (
                  <div className="coverage-item" key={index}>
                    {/* vehicle year & make */}
                    <h1 className="vehicle-title">
                      {`${vehicle.year} ${vehicle.make}`}
                    </h1>
                    {/* vehicle model */}
                    <h2 className="vehicle-subtitle">{`${vehicle.model}`}</h2>
                    {/* coverage input */}
                    <CollisionDeductible index={index} />
                    <ComprehensiveDeductible index={index} />
                    <RoadsideCoverage index={index} />
                    <TransportationExpense index={index} />
                    <AutoReplacement index={index} />
                  </div>
                )
              })
            }
          </div>

          <hr className="line" />
          <div className="btn-container">
            {/* back button */}
            <button
              type="button"
              className="btn back-btn"
              onClick={() => handlePrevStep(values)}
            >
              Back
            </button>
            {/* next button */}
            <button className="btn next-btn" type="submit">
              Next
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default FormFour
