const data = [
  {
    sys: {
      id: "6",
    },
    fields: {
      type: "individual",
      title: "Collision Deductible:",
      name: "collisionDeductible",
      isRequired: false,
      description:
        "If you hit another car and/or object, collision provides physical damage protection to your vehicle. We will pay to fix your vehicle minus your collision deductible. Below are your current coverages:",
      selection: [
        "none",
        "$50",
        "$100",
        "$150",
        "$200",
        "$250",
        "$500",
        "$600",
        "$750",
        "$1000",
        "$1250",
        "$1500",
        "$2000",
      ],
    },
  },
  {
    sys: {
      id: "7",
    },
    fields: {
      type: "individual",
      title: "Collision for Custom Equipment:",
      name: "collisionCustom",
      isRequired: false,
      description:
        "This is extra coverage that you can purchase for aftermarket, special or custom parts you have added on your vehicle. Below are your current coverages:",
      selection: [
        "none",
        "$2,500",
        "$5,000",
        "$10,000",
        "$15,000",
        "$20,000",
        "$25,000",
      ],
    },
  },
  {
    sys: {
      id: "8",
    },
    fields: {
      type: "individual",
      title: "Comprehensive Deductible:",
      name: "comprehensiveDeductible",
      isRequired: false,
      description:
        "Losses such as broken windshields, theft, vandalism, fire, wind, and storm damage are frequent claims covered by the comprehensive deductible. Below are your current coverages:",
      selection: [
        "none",
        "$0",
        "$50",
        "$100",
        "$150",
        "$200",
        "$250",
        "$500",
        "$600",
        "$750",
        "$1000",
        "$1250",
        "$1500",
        "$2000",
      ],
    },
  },
  {
    sys: {
      id: "9",
    },
    fields: {
      type: "individual",
      title: "Comprehensive for Custom Equipment:",
      name: "comprehensiveCustom",
      isRequired: false,
      description:
        "This is extra coverage that you can purchase for aftermarket, special or custom parts you have added on your vehicle. Below are your current coverages:",
      selection: [
        "none",
        "$2,500",
        "$5,000",
        "$10,000",
        "$15,000",
        "$20,000",
        "$25,000",
      ],
    },
  },
  {
    sys: {
      id: "10",
    },
    fields: {
      type: "individual",
      title: "Transportation Expense:",
      name: "transportationExpense",
      isRequired: false,
      description:
        "Transportation expense will help you cover your additional transportation expenses up to the amount you selected per day. Below are your current coverages:",
      selection: ["none", "$20", "$30", "$40", "$50", "$100"],
    },
  },
  {
    sys: {
      id: "11",
    },
    fields: {
      type: "individual",
      title: "Roadside Coverage:",
      name: "roadsideCoverage",
      isRequired: false,
      description:
        "Roadside coverage gives you coverage should your vehicle become disabled. We will reimburse you for towing or emergency road services up to the amount listed below:",
      selection: ["none", "$50", "$80", "$100", "$120"],
    },
  },
  {
    sys: {
      id: "12",
    },
    fields: {
      type: "individual",
      title: "Sound System:",
      name: "soundSystem",
      isRequired: false,
      description:
        "Sound system coverage is needed to protect additional, permanently installed sound systems. Below are your current coverages:",
      selection: ["none", "$250", "$500", "$1,000", "$2,500"],
    },
  },
  {
    sys: {
      id: "13",
    },
    fields: {
      type: "individual",
      title: "Portable Electronics and Media:",
      name: "portableElectronics",
      isRequired: false,
      description:
        "If personal electronics devices are either damaged or stolen from your vehicle, we will replace your items minus your deductible.",
      selection: ["none", "$100", "$200", "$250", "$1,000"],
    },
  },
  {
    sys: {
      id: "14",
    },
    fields: {
      type: "individual",
      title: "Auto Replacement Protection:",
      name: "autoReplacement",
      isRequired: false,
      description:
        "If you are in an accident that results in a total loss of your vehicle, by having this coverage we will replace your vehicle with a vehicle that is the same make and model, which is one year newer and 20,000 miles less on it.",
      selection: ["none", "purchased"],
    },
  },
  {
    sys: {
      id: "15",
    },
    fields: {
      type: "individual",
      title: "Ride for Hire Endorsement:",
      name: "rideHire",
      isRequired: false,
      description:
        "If you drive one of the vehicles on your policy for a ride share company, we recommend you add this coverage to your policy:",
      selection: ["none", "purchased"],
    },
  },
]

export default data
