import React from "react"
import { useAutoContext } from "../../context/auto_context"

const VehicleList = () => {
  const { vehicles } = useAutoContext()

  return (
    <>
      <div className="vehicle-list">
        <ul>
          {vehicles.map((item, index) => {
            return (
              <li key={index} className="vehicle-item">
                {item.year} {item.make} {item.model}
              </li>
            )
          })}
        </ul>
      </div>
      <br />
    </>
  )
}

export default VehicleList
