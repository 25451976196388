// import { BatchPrediction } from "@mui/icons-material"
import React from "react"
import { useAutoContext } from "../../context/auto_context"
import BodilyInjury from "./BodilyInjury"
import Policy from "./Policy"
import PropertyDamage from "./PropertyDamage"
import UnderinsuredMotorist from "./UnderinsuredMotorist"
import UninsuredMotorist from "./UninsuredMotorist"
import VehicleList from "./VehicleList"

// Document Component
const MyDocument = () => {
  const { vehicles, allCoverage, data, allData, drivers } = useAutoContext()

  return (
    <>
      <div>
        <p>Dear [firstname],</p>
        <br />

        {/* Policy */}
        <Policy />

        <p>
          To ensure that you are aware of your coverages, we have put together
          the following review of your policy.
        </p>
        <br />
        <h3>Coverage Explanations</h3>
        <br />
        <p>
          <strong>Vehicles on your Policy:</strong> Below you will see the year,
          make and model of all vehicles listed on your policy. Coverage may be
          in jeopardy if these vehicles are not titled in your name.
        </p>
        <br />

        {/* list of vehicles */}
        <VehicleList />

        {/* All vehicle coverage */}
        <BodilyInjury />
        <PropertyDamage />
        <UninsuredMotorist />
        <UnderinsuredMotorist />

        {/* individual vehicle coverage */}
        {data.map((item, index) => {
          const name = item.fields.name

          return (
            <div key={index}>
              {/* title */}
              <h3 className="coverage-title">{item.fields.title}</h3>
              {/* description */}
              <p>{item.fields.description}</p>
              <br />
              {/* vehicles */}
              <div className="vehicle-list">
                <ul>
                  {vehicles.map((vehicle, index) => {
                    return (
                      <li key={index} className="vehicle-coverage-item">
                        {vehicle.year} {vehicle.make} {vehicle.model}:{" "}
                        <strong>
                          {vehicle.coverage[name] || "not purchased"}
                        </strong>
                      </li>
                    )
                  })}
                </ul>
              </div>
              <br />
            </div>
          )
        })}

        {/* drivers */}
        <div>
          <h3 className="driver-title">Drivers on your Policy:</h3>
          <p>
            The following individuals are those persons who you have selected to
            drive your vehicles on a regular basis:
          </p>
          <br />
          <div className="driver-list">
            <ul>
              {drivers.map((driver, index) => {
                return (
                  <li key={index} className="driver-item">
                    {driver.firstName} {driver.lastName}
                  </li>
                )
              })}
            </ul>
          </div>
          <br />
          <p>
            *If there are additional persons that drive your vehicles or if any
            of these vehicles are not garaged at your home, please let us know
            immediately.*
          </p>
        </div>
        <br />

        {/* Salutations */}
        <p>
          Thank you for being a member of our agency, we recognize that there
          are plenty of other options out there. We strive to give you the best
          service we can. If there is anything, we can help you with, please let
          us know.
        </p>
        <br />
        <p>Best Regards,</p>
        <p>[signature]</p>
      </div>
    </>
  )
}

export default MyDocument
