import React from "react"
import { useAutoContext } from "../../../context/auto_context"
import Dropdown from "../Dropdown"

const RideHire = ({ index }) => {
  const { data } = useAutoContext()
  const rideHire = data.filter(item => item.fields.name === "rideHire")[0]

  return (
    <>
      <Dropdown
        title={rideHire.fields.title}
        name={rideHire.fields.name}
        key={rideHire.sys.id}
        id={rideHire.sys.id}
        type={rideHire.fields.type}
        isRequired={rideHire.fields.isRequired}
        selection={[...rideHire.fields.selection]}
        index={index}
      />
    </>
  )
}

export default RideHire
