import React, { useState, useEffect } from "react"
// import useFetch from "./useFetch"
import { useAutoContext } from "../../context/auto_context"

const VehicleModel = ({ index, year, make, model }) => {
  const [models, setModels] = useState()
  const [loading, setLoading] = useState(true)
  const { handleChange } = useAutoContext()
  const url =
    "https://vehapi.com/api/v1/car-lists/get/car/models/" + year + "/" + make

  // start
  const myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")
  myHeaders.append("X-Requested-With", "XMLHttpRequest")
  myHeaders.append(
    "Authorization",
    "Bearer " + process.env.GATSBY_VEHICLE_API_TOKEN
  )
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  }

  const getModels = async () => {
    try {
      const response = await fetch(url, requestOptions)
      const result = await response.json()

      setModels(result)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getModels()
  }, [])
  // end

  return (
    <select
      name="model"
      id={index}
      value={model}
      onChange={handleChange}
      required
    >
      {/* placeholder */}
      <option className="placeholder" value="">
        Model
      </option>

      {/* models */}
      {loading
        ? "...loading"
        : models.map((item, index) => (
            <option key={index} value={item.model}>
              {item.model}
            </option>
          ))}
    </select>
  )
}

export default VehicleModel
