import React, { useState, useEffect } from "react"
// import useFetch from "./useFetch"
import { useAutoContext } from "../../context/auto_context"

const VehicleYear = ({ index }) => {
  const [years, setYears] = useState()
  const [loading, setLoading] = useState(true)
  const { vehicles, handleChange } = useAutoContext()
  const url = "https://vehapi.com/api/v1/car-lists/get/car/years/desc"
  // start
  const myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")
  myHeaders.append("X-Requested-With", "XMLHttpRequest")
  myHeaders.append(
    "Authorization",
    "Bearer " + process.env.GATSBY_VEHICLE_API_TOKEN
  )
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  }

  const getYears = async () => {
    try {
      const response = await fetch(url, requestOptions)
      if (!response.ok) {
        const msg = `There was an error "${response.status} ${response.statusText}"}`
        throw new Error(msg)
      }
      const result = await response.json()
      setYears(result)
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getYears()
  }, [])
  // end

  return (
    <select
      name="year"
      id={index}
      value={vehicles[index].year}
      onChange={handleChange}
      required
    >
      {/* placeholder */}
      <option className="placeholder" value="">
        Year
      </option>
      {/* year selection */}
      {loading
        ? "...loading"
        : years.map((item, index) => (
            <option key={index} value={item.year}>
              {item.year}
            </option>
          ))}
    </select>
  )
}

export default VehicleYear
