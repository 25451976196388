import React from "react"
import { useAutoContext } from "../../context/auto_context"

const BodilyInjury = () => {
  const { allCoverage } = useAutoContext()
  const { bodilyInjury } = allCoverage

  return (
    <>
      <h3>Bodily Injury:</h3>
      <p>
        {bodilyInjury === "25/65"
          ? "In a covered accident initiated by you, those that you hurt will be covered up to $25,000 per person but it will not exceed $65,000 for the entire accident. We recommend our customers carry Bodily Injury limits of at least 100/300 or higher."
          : bodilyInjury === "25/100"
          ? "In a covered accident initiated by you, those that you hurt will be covered up to $25,000 per person but it will not exceed $100,000 for the entire accident. We recommend our customers carry Bodily Injury limits of at least 100/300 or higher."
          : bodilyInjury === "30/60"
          ? "In a covered accident initiated by you, those that you hurt will be covered up to $30,000 per person but it will not exceed $60,000 for the entire accident. We recommend our customers carry Bodily Injury limits of at least 100/300 or higher."
          : bodilyInjury === "30/85"
          ? "In a covered accident initiated by you, those that you hurt will be covered up to $30,000 per person but it will not exceed $85,000 for the entire accident. We recommend our customers carry Bodily Injury limits of at least 100/300 or higher."
          : bodilyInjury === "50/100"
          ? "In a covered accident initiated by you, those that you hurt will be covered up to $50,000 per person but it will not exceed $100,000 for the entire accident. We recommend our customers carry Bodily Injury limits of at least 100/300 or higher."
          : bodilyInjury === "100/300"
          ? "In a covered accident initiated by you, those that you hurt will be covered up to $100,000 per person but it will not exceed $300,000 for the entire accident. In order to protect your assets, we recommend increasing your limits to 250/500."
          : bodilyInjury === "200/300"
          ? "In a covered accident initiated by you, those that you hurt will be covered up to $200,000 per person but it will not exceed $300,000 for the entire accident. In order to protect your assets, we recommend increasing your limits to 250/500."
          : bodilyInjury === "300/300"
          ? "In a covered accident initiated by you, those that you hurt will be covered up to $300,000 per person but it will not exceed $300,000 for the entire accident. In order to protect your assets, we recommend increasing your limits to 250/500."
          : bodilyInjury === "250/500"
          ? "In a covered accident initiated by you, those that you potentially hurt will be covered up to $250,000 per person but it will not exceed $500,000 for the entire accident. In order to protect your assets, we recommend carrying a personal umbrella policy."
          : bodilyInjury === "300/500"
          ? "In a covered accident initiated by you, those that you potentially hurt will be covered up to $300,000 per person but it will not exceed $500,000 for the entire accident. In order to protect your assets, we recommend carrying a personal umbrella policy."
          : "In a covered accident initiated by you, those that you potentially hurt will be covered up to $500,000 per person but it will not exceed $500,000 for the entire accident. In order to protect your assets, we recommend carrying a personal umbrella policy."}
      </p>
      <br />
    </>
  )
}

export default BodilyInjury
