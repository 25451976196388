import React from "react"
import { useAutoContext } from "../../context/auto_context"

const UnderinsuredMotorist = () => {
  const { allCoverage } = useAutoContext()
  const { underinsuredMotorist } = allCoverage

  return (
    <>
      <h3>Underinsured Motorist:</h3>
      <p>
        {underinsuredMotorist === "25/65"
          ? "In a covered accident, initiated by someone else who does not have enough insurance, you would be covered up to $25,000 per person but it would not exceed $65,000 for the entire accident."
          : underinsuredMotorist === "25/100"
          ? "In a covered accident, initiated by someone else who does not have enough insurance, you would be covered up to $25,000 per person but it would not exceed $100,000 for the entire accident."
          : underinsuredMotorist === "30/60"
          ? "In a covered accident, initiated by someone else who does not have enough insurance, you would be covered up to $30,000 per person but it would not exceed $60,000 for the entire accident."
          : underinsuredMotorist === "30/85"
          ? "In a covered accident, initiated by someone else who does not have enough insurance, you would be covered up to $30,000 per person but it would not exceed $85,000 for the entire accident."
          : underinsuredMotorist === "50/100"
          ? "In a covered accident, initiated by someone else who does not have enough insurance, you would be covered up to $50,000 per person but it would not exceed $100,000 for the entire accident."
          : underinsuredMotorist === "100/300"
          ? "In a covered accident, initiated by someone else who does not have enough insurance, you would be covered up to $100,000 per person but it would not exceed $300,000 for the entire accident."
          : underinsuredMotorist === "200/300"
          ? "In a covered accident, initiated by someone else who does not have enough insurance, you would be covered up to $200,000 per person but it would not exceed $300,000 for the entire accident."
          : underinsuredMotorist === "300/300"
          ? "In a covered accident, initiated by someone else who does not have enough insurance, you would be covered up to $300,000 per person but it would not exceed $300,000 for the entire accident."
          : underinsuredMotorist === "250/500"
          ? "In a covered accident initiated by you, those that you potentially hurt will be covered up to $250,000 per person but it will not exceed $500,000 for the entire accident. In order to protect your assets, we recommend carrying a personal umbrella policy."
          : underinsuredMotorist === "300/500"
          ? "In a covered accident, initiated by someone else who does not have enough insurance, you would be covered up to $300,000 per person but it would not exceed $500,000 for the entire accident."
          : underinsuredMotorist === "500/500"
          ? "In a covered accident, initiated by someone else who does not have enough insurance, you would be covered up to $500,000 per person but it would not exceed $500,000 for the entire accident."
          : "In a covered accident, initiated by someone else who does not have enough insurance, you DO NOT have any coverage."}
      </p>
      <br />
    </>
  )
}

export default UnderinsuredMotorist
