import React from "react"
import { useAutoContext } from "../../../context/auto_context"
import Dropdown from "../Dropdown"

const TransportationExpense = ({ index }) => {
  const { data } = useAutoContext()
  const transportationExpense = data.filter(
    item => item.fields.name === "transportationExpense"
  )[0]

  return (
    <>
      <Dropdown
        title={transportationExpense.fields.title}
        name={transportationExpense.fields.name}
        key={transportationExpense.sys.id}
        id={transportationExpense.sys.id}
        type={transportationExpense.fields.type}
        isRequired={transportationExpense.fields.isRequired}
        selection={[...transportationExpense.fields.selection]}
        index={index}
      />
    </>
  )
}

export default TransportationExpense
