import React from "react"
import { useAutoContext } from "../../../context/auto_context"
import Dropdown from "../Dropdown"

const RoadsideCoverage = ({ index }) => {
  const { data } = useAutoContext()
  const roadsideCoverage = data.filter(
    item => item.fields.name === "roadsideCoverage"
  )[0]

  return (
    <>
      <Dropdown
        title={roadsideCoverage.fields.title}
        name={roadsideCoverage.fields.name}
        key={roadsideCoverage.sys.id}
        id={roadsideCoverage.sys.id}
        type={roadsideCoverage.fields.type}
        isRequired={roadsideCoverage.fields.isRequired}
        selection={[...roadsideCoverage.fields.selection]}
        index={index}
      />
    </>
  )
}

export default RoadsideCoverage
