const allVehicleCoverages = [
  {
    sys: {
      id: "1",
    },
    fields: {
      type: "all",
      title: "Policy:",
      name: "policy",
      isRequired: true,
      selection: ["new", "renew"],
      description: [
        "We are thrilled to have you as a member of our agency!",

        "Your auto insurance policy is about to renew and this presents us with a good time to discuss any concerns or questions that you may have about your policy.",
      ],
    },
  },
  {
    sys: {
      id: "2",
    },
    fields: {
      type: "all",
      title: "Bodily Injury Liability:",
      name: "bodilyInjury",
      isRequired: true,
      selection: [
        "25/65",
        "25/100",
        "30/60",
        "30/85",
        "50/100",
        "100/300",
        "200/300",
        "300/300",
        "250/500",
        "300/500",
        "500/500",
      ],
      description: [
        "In a covered accident initiated by you, those that you hurt will be covered up to $25,000 per person but it will not exceed $65,000 for the entire accident. We recommend our customers carry Bodily Injury limits of at least 100/300 or higher.",

        "In a covered accident initiated by you, those that you hurt will be covered up to $25,000 per person but it will not exceed $100,000 for the entire accident. We recommend our customers carry Bodily Injury limits of at least 100/300 or higher.",

        "In a covered accident initiated by you, those that you hurt will be covered up to $30,000 per person but it will not exceed $60,000 for the entire accident. We recommend our customers carry Bodily Injury limits of at least 100/300 or higher.",

        "In a covered accident initiated by you, those that you hurt will be covered up to $30,000 per person but it will not exceed $85,000 for the entire accident. We recommend our customers carry Bodily Injury limits of at least 100/300 or higher.",

        "In a covered accident initiated by you, those that you hurt will be covered up to $50,000 per person but it will not exceed $100,000 for the entire accident. We recommend our customers carry Bodily Injury limits of at least 100/300 or higher.",

        "In a covered accident initiated by you, those that you hurt will be covered up to $100,000 per person but it will not exceed $300,000 for the entire accident. In order to protect your assets, we recommend increasing your limits to 250/500.",

        "In a covered accident initiated by you, those that you hurt will be covered up to $200,000 per person but it will not exceed $300,000 for the entire accident. In order to protect your assets, we recommend increasing your limits to 250/500.",

        "In a covered accident initiated by you, those that you potentially hurt will be covered up to $300,000 per person but it will not exceed $300,000 for the entire accident. In order to protect your assets, we recommend carrying a personal umbrella policy.",

        "In a covered accident initiated by you, those that you potentially hurt will be covered up to $250,000 per person but it will not exceed $500,000 for the entire accident. In order to protect your assets, we recommend carrying a personal umbrella policy.",

        "In a covered accident initiated by you, those that you potentially hurt will be covered up to $300,000 per person but it will not exceed $500,000 for the entire accident. In order to protect your assets, we recommend carrying a personal umbrella policy.",

        "In a covered accident initiated by you, those that you potentially hurt will be covered up to $500,000 per person but it will not exceed $500,000 for the entire accident. In order to protect your assets, we recommend carrying a personal umbrella policy.",
      ],
    },
  },
  {
    sys: {
      id: "3",
    },
    fields: {
      type: "all",
      title: "Property Damage Liability:",
      name: "propertyDamage",
      isRequired: true,
      selection: [
        "$15,000",
        "$25,000",
        "$50,000",
        "$100,000",
        "$200,000",
        "$300,000",
        "$400,000",
        "$500,000",
      ],
      description: [
        "In a covered accident initiated by you, which results in damage to someone else’s property, the maximum that would be covered would be $15,000 of damage.",

        "In a covered accident initiated by you, which results in damage to someone else’s property, the maximum that would be covered would be $25,000 of damage.",

        "In a covered accident initiated by you, which results in damage to someone else’s property, the maximum that would be covered would be $50,000 of damage.",

        "In a covered accident initiated by you, which results in damage to someone else’s property, the maximum that would be covered would be $100,000 of damage.",

        "In a covered accident initiated by you, which results in damage to someone else’s property, the maximum that would be covered would be $200,000 of damage.",

        "In a covered accident initiated by you, which results in damage to someone else’s property, the maximum that would be covered would be $300,000 of damage.",

        "In a covered accident initiated by you, which results in damage to someone else’s property, the maximum that would be covered would be $400,000 of damage.",

        "In a covered accident initiated by you, which results in damage to someone else’s property, the maximum that would be covered would be $500,000 of damage.",
      ],
    },
  },
  {
    sys: {
      id: "4",
    },
    fields: {
      type: "all",
      title: "Uninsured Motorist Bodily Injury:",
      name: "uninsuredMotorist",
      isRequired: true,
      selection: [
        "none",
        "25/65",
        "25/100",
        "30/60",
        "30/85",
        "50/100",
        "100/300",
        "200/300",
        "300/300",
        "250/500",
        "300/500",
        "500/500",
      ],
      description: [
        "In a covered accident, initiated by someone else who does not have any insurance, you DO NOT have any coverage.",

        "In a covered accident, initiated by someone else who does not have any insurance, you would be covered up to $25,000 per person but it would not exceed $65,000 for the entire accident.",

        "In a covered accident, initiated by someone else who does not have any insurance, you would be covered up to $25,000 per person but it would not exceed $100,000 for the entire accident.",

        "In a covered accident, initiated by someone else who does not have any insurance, you would be covered up to $30,000 per person but it would not exceed $60,000 for the entire accident.",

        "In a covered accident, initiated by someone else who does not have any insurance, you would be covered up to $30,000 per person but it would not exceed $85,000 for the entire accident.",

        "In a covered accident, initiated by someone else who does not have any insurance, you would be covered up to $50,000 per person but it would not exceed $100,000 for the entire accident.",

        "In a covered accident, initiated by someone else who does not have any insurance, you would be covered up to $100,000 per person but it would not exceed $300,000 for the entire accident.",

        "In a covered accident, initiated by someone else who does not have any insurance, you would be covered up to $200,000 per person but it would not exceed $300,000 for the entire accident",

        "In a covered accident, initiated by someone else who does not have any insurance, you would be covered up to $300,000 per person but it would not exceed $300,000 for the entire accident.",

        "In a covered accident, initiated by someone else who does not have any insurance, you would be covered up to $250,000 per person but it would not exceed $500,000 for the entire accident.",

        "In a covered accident, initiated by someone else who does not have any insurance, you would be covered up to $300,000 per person but it would not exceed $500,000 for the entire accident.",

        "In a covered accident, initiated by someone else who does not have any insurance, you would be covered up to $500,000 per person but it would not exceed $500,000 for the entire accident.",
      ],
    },
  },
  {
    sys: {
      id: "5",
    },
    fields: {
      type: "all",
      title: "Underinsured Motorist Bodily Injury:",
      name: "underinsuredMotorist",
      isRequired: true,
      selection: [
        "none",
        "25/65",
        "25/100",
        "30/60",
        "30/85",
        "50/100",
        "100/300",
        "200/300",
        "300/300",
        "250/500",
        "300/500",
        "500/500",
      ],
      description: [
        "In a covered accident, initiated by someone else who does not have any insurance, you DO NOT have any coverage.",

        "In a covered accident, initiated by someone else who does not have enough insurance, you would be covered up to $25,000 per person but it would not exceed $65,000 for the entire accident.",

        "In a covered accident, initiated by someone else who does not have enough insurance, you would be covered up to $25,000 per person but it would not exceed $100,000 for the entire accident.",

        "In a covered accident, initiated by someone else who does not have enough insurance, you would be covered up to $30,000 per person but it would not exceed $60,000 for the entire accident.",

        "In a covered accident, initiated by someone else who does not have enough insurance, you would be covered up to $30,000 per person but it would not exceed $85,000 for the entire accident.",

        "In a covered accident, initiated by someone else who does not have enough insurance, you would be covered up to $50,000 per person but it would not exceed $100,000 for the entire accident.",

        "In a covered accident, initiated by someone else who does not have enough insurance, you would be covered up to $100,000 per person but it would not exceed $300,000 for the entire accident.",

        "In a covered accident, initiated by someone else who does not have enough insurance, you would be covered up to $200,000 per person but it would not exceed $300,000 for the entire accident.",

        "In a covered accident, initiated by someone else who does not have enough insurance, you would be covered up to $300,000 per person but it would not exceed $300,000 for the entire accident.",

        "In a covered accident, initiated by someone else who does not have enough insurance, you would be covered up to $250,000 per person but it would not exceed $500,000 for the entire accident.",

        "In a covered accident, initiated by someone else who does not have enough insurance, you would be covered up to $300,000 per person but it would not exceed $500,000 for the entire accident.",

        "In a covered accident, initiated by someone else who does not have enough insurance, you would be covered up to $500,000 per person but it would not exceed $500,000 for the entire accident.",
      ],
    },
  },
]

export default allVehicleCoverages
