import React from "react"
import { useAutoContext } from "../../../context/auto_context"
import Dropdown from "../Dropdown"

const SoundSystem = ({ index }) => {
  const { data } = useAutoContext()
  const soundSystem = data.filter(item => item.fields.name === "soundSystem")[0]

  return (
    <>
      <Dropdown
        title={soundSystem.fields.title}
        name={soundSystem.fields.name}
        key={soundSystem.sys.id}
        id={soundSystem.sys.id}
        type={soundSystem.fields.type}
        isRequired={soundSystem.fields.isRequired}
        selection={[...soundSystem.fields.selection]}
        index={index}
      />
    </>
  )
}

export default SoundSystem
