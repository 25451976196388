import React from "react"
import { useAutoContext } from "../../../context/auto_context"
import Dropdown from "../Dropdown"

const CollisionCustom = ({ index }) => {
  const { data } = useAutoContext()
  const collisionCustom = data.filter(
    item => item.fields.name === "collisionCustom"
  )[0]

  return (
    <>
      <Dropdown
        title={collisionCustom.fields.title}
        name={collisionCustom.fields.name}
        key={collisionCustom.sys.id}
        id={collisionCustom.sys.id}
        type={collisionCustom.fields.type}
        isRequired={collisionCustom.fields.isRequired}
        selection={[...collisionCustom.fields.selection]}
        index={index}
      />
    </>
  )
}

export default CollisionCustom
