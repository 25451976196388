import React from "react"
import { useAutoContext } from "../../../context/auto_context"
import Dropdown from "../Dropdown"

const PortableElectronics = ({ index }) => {
  const { data } = useAutoContext()
  const portableElectronics = data.filter(
    item => item.fields.name === "portableElectronics"
  )[0]

  return (
    <>
      <Dropdown
        title={portableElectronics.fields.title}
        name={portableElectronics.fields.name}
        key={portableElectronics.sys.id}
        id={portableElectronics.sys.id}
        type={portableElectronics.fields.type}
        isRequired={portableElectronics.fields.isRequired}
        selection={[...portableElectronics.fields.selection]}
        index={index}
      />
    </>
  )
}

export default PortableElectronics
