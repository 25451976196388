import React from "react"
import { useAutoContext } from "../../../context/auto_context"
import Dropdown from "../AllDropdown"

const Policy = () => {
  const { allData } = useAutoContext()
  const policy = allData.filter(item => item.fields.name === "policy")[0]

  return (
    <>
      <Dropdown
        title={policy.fields.title}
        name={policy.fields.name}
        key={policy.sys.id}
        id={policy.sys.id}
        type={policy.fields.type}
        isRequired={policy.fields.isRequired}
        selection={[...policy.fields.selection]}
      />
    </>
  )
}

export default Policy
