import React from "react"
import { useAutoContext } from "../../../context/auto_context"
import Dropdown from "../AllDropdown"

const PropertyDamage = () => {
  const { allData } = useAutoContext()
  const propertyDamage = allData.filter(
    item => item.fields.name === "propertyDamage"
  )[0]

  return (
    <>
      <Dropdown
        title={propertyDamage.fields.title}
        name={propertyDamage.fields.name}
        key={propertyDamage.sys.id}
        id={propertyDamage.sys.id}
        type={propertyDamage.fields.type}
        isRequired={propertyDamage.fields.isRequired}
        selection={[...propertyDamage.fields.selection]}
      />
    </>
  )
}

export default PropertyDamage
