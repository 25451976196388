import React from "react"
import { useAutoContext } from "../../../context/auto_context"
import Dropdown from "../Dropdown"

const ComprehensiveDeductible = ({ index }) => {
  const { data } = useAutoContext()
  const comprehensiveDeductible = data.filter(
    item => item.fields.name === "comprehensiveDeductible"
  )[0]

  return (
    <>
      <Dropdown
        title={comprehensiveDeductible.fields.title}
        name={comprehensiveDeductible.fields.name}
        key={comprehensiveDeductible.sys.id}
        id={comprehensiveDeductible.sys.id}
        type={comprehensiveDeductible.fields.type}
        isRequired={comprehensiveDeductible.fields.isRequired}
        selection={[...comprehensiveDeductible.fields.selection]}
        index={index}
      />
    </>
  )
}

export default ComprehensiveDeductible
