import React from "react"
import { useAutoContext } from "../../../context/auto_context"
import Dropdown from "../Dropdown"

const AutoReplacement = ({ index }) => {
  const { data } = useAutoContext()
  const autoReplacement = data.filter(
    item => item.fields.name === "autoReplacement"
  )[0]

  return (
    <>
      <Dropdown
        title={autoReplacement.fields.title}
        name={autoReplacement.fields.name}
        key={autoReplacement.sys.id}
        id={autoReplacement.sys.id}
        type={autoReplacement.fields.type}
        isRequired={autoReplacement.fields.isRequired}
        selection={[...autoReplacement.fields.selection]}
        index={index}
      />
    </>
  )
}

export default AutoReplacement
