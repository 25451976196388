import React from "react"
import { useAutoContext } from "../../context/auto_context"

const Policy = () => {
  const { allCoverage } = useAutoContext()
  const { policy } = allCoverage

  return (
    <>
      <p>
        {policy === "new"
          ? "We are thrilled to have you as a member of our agency!"
          : "Your auto insurance policy is about to renew and this presents us with a good time to discuss any concerns or questions that you may have about your policy."}
      </p>
      <br />
    </>
  )
}

export default Policy

// {
//   ;(() => {
//     switch ({ policy }) {
//       case "new":
//         "We are thrilled to have you as a member of our agency!"
//         break
//       case "renew":
//         "Your auto insurance policy is about to renew and this presents us with a good time to discuss any concerns or questions that you may have about your policy."
//         break
//       default:
//         break
//     }
//   })()
// }
