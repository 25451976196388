import React from "react"
import { Formik, Form } from "formik"
import { useAutoContext } from "../../context/auto_context"
import CollisionCustom from "./inputs/CollisionCustom"
import ComprehensiveCustom from "./inputs/ComprehensiveCustom"
import SoundSystem from "./inputs/SoundSystem"
import PortableElectronics from "./inputs/PortableElectronics"
import RideHire from "./inputs/RideHire"

const FormFive = () => {
  const { vehicles, handlePrevStep, handleNextStep } = useAutoContext()

  return (
    <Formik initialValues={vehicles} onSubmit={handleNextStep}>
      {({ values }) => (
        <Form>
          <div className="coverages">
            {
              // vehicle coverages
              vehicles.map((vehicle, index) => {
                return (
                  <div className="coverage-item" key={index}>
                    {/* vehicle year & make */}
                    <h1 className="vehicle-title">
                      {`${vehicle.year} ${vehicle.make}`}
                    </h1>
                    {/* vehicle model */}
                    <h2 className="vehicle-subtitle">{`${vehicle.model}`}</h2>
                    {/* coverage input */}
                    <CollisionCustom index={index} />
                    <ComprehensiveCustom index={index} />
                    <SoundSystem index={index} />
                    <PortableElectronics index={index} />
                    <RideHire index={index} />
                  </div>
                )
              })
            }
          </div>

          <hr className="line" />
          <div className="btn-container">
            {/* back button */}
            <button
              type="button"
              className="btn back-btn"
              onClick={() => handlePrevStep(values)}
            >
              Back
            </button>
            {/* next button */}
            <button className="btn next-btn" type="submit">
              Next
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default FormFive
