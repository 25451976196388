import React from "react"
import DriverInput from "./DriverInput"
import { MdAdd } from "react-icons/md"
import { useAutoContext } from "../../context/auto_context"

const Drivers = () => {
  const { drivers, addDriver } = useAutoContext()

  return (
    <div className="row">
      <h2>Drivers</h2>

      {/* driver input */}
      {drivers.map((driver, index) => {
        return <DriverInput key={index} index={index} driver={driver} />
      })}

      {/* add driver */}
      <div className="add-vehicle-container">
        <button
          className="add-vehicle"
          id="add-vehicle"
          type="button"
          onClick={addDriver}
        >
          add driver
          <MdAdd />
        </button>
      </div>
    </div>
  )
}

export default Drivers
