import React from "react"
import { useAutoContext } from "../../context/auto_context"

const PropertyDamage = () => {
  const { allCoverage } = useAutoContext()
  const { propertyDamage } = allCoverage

  return (
    <>
      <h3>Property Damage:</h3>
      <p>
        {propertyDamage === "$15,000"
          ? "In a covered accident initiated by you, which results in damage to someone else’s property, the maximum that would be covered would be $15,000 of damage."
          : propertyDamage === "$25,000"
          ? "In a covered accident initiated by you, which results in damage to someone else’s property, the maximum that would be covered would be $25,000 of damage."
          : propertyDamage === "$50,000"
          ? "In a covered accident initiated by you, which results in damage to someone else’s property, the maximum that would be covered would be $50,000 of damage."
          : propertyDamage === "$100,000"
          ? "In a covered accident initiated by you, which results in damage to someone else’s property, the maximum that would be covered would be $100,000 of damage."
          : propertyDamage === "$200,000"
          ? "In a covered accident initiated by you, which results in damage to someone else’s property, the maximum that would be covered would be $200,000 of damage."
          : propertyDamage === "$300,000"
          ? "In a covered accident initiated by you, which results in damage to someone else’s property, the maximum that would be covered would be $300,000 of damage."
          : propertyDamage === "$400,000"
          ? "In a covered accident initiated by you, which results in damage to someone else’s property, the maximum that would be covered would be $400,000 of damage."
          : "In a covered accident initiated by you, which results in damage to someone else’s property, the maximum that would be covered would be $500,000 of damage."}
      </p>
      <br />
    </>
  )
}

export default PropertyDamage
