import React from "react"
import { useAutoContext } from "../../../context/auto_context"
import Dropdown from "../AllDropdown"

const UnderinsuredMotorist = () => {
  const { allData } = useAutoContext()
  const underinsuredMotorist = allData.filter(
    item => item.fields.name === "underinsuredMotorist"
  )[0]

  return (
    <>
      <Dropdown
        title={underinsuredMotorist.fields.title}
        name={underinsuredMotorist.fields.name}
        key={underinsuredMotorist.sys.id}
        id={underinsuredMotorist.sys.id}
        type={underinsuredMotorist.fields.type}
        isRequired={underinsuredMotorist.fields.isRequired}
        selection={[...underinsuredMotorist.fields.selection]}
      />
    </>
  )
}

export default UnderinsuredMotorist
