import React from "react"
// Vehicle form
import FormOne from "./FormOne"
// Driver form
import FormTwo from "./FormTwo"
// Overall Coverage form
import FormThree from "./FormThree"
// Individual Coverage form
import FormFour from "./FormFour"
import MyDocument from "./MyDocument"
import Login from "../global/Login"
import Loading from "../global/Loading"
import BackToTop from "../global/BackToTop"
import { useAutoContext } from "../../context/auto_context"
import { useAuth0 } from "@auth0/auth0-react"
import FormFive from "./FormFive"

const Main = () => {
  const { currentStep } = useAutoContext()
  const { isAuthenticated, isLoading } = useAuth0()

  let steps = [
    <FormOne />,
    <FormTwo />,
    <FormThree />,
    <FormFour />,
    <FormFive />,
    <MyDocument />,
  ]

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <section className="section">
        {isAuthenticated ? steps[currentStep] : <Login />}
      </section>
      {currentStep === steps.length - 1 && <BackToTop />}
    </>
  )
}

export default Main
