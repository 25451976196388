import React from "react"
import { Formik, Form } from "formik"
import { useAutoContext } from "../../context/auto_context"
import BodilyInjury from "./inputs/BodilyInjury"
import Policy from "./inputs/Policy"
import PropertyDamage from "./inputs/PropertyDamage"
import UninsuredMotorist from "./inputs/UninsuredMotorist"
import UnderinsuredMotorist from "./inputs/UnderinsuredMotorist"

const FormThree = () => {
  const { allCoverage, handlePrevStep, handleNextCoverageStep } =
    useAutoContext()

  return (
    <Formik initialValues={allCoverage} onSubmit={handleNextCoverageStep}>
      {({ values }) => (
        <Form>
          <h2>Coverages</h2>

          <Policy />
          <BodilyInjury />
          <PropertyDamage />
          <UninsuredMotorist />
          <UnderinsuredMotorist />

          <hr className="line" />

          {/* buttons */}
          <div className="btn-container">
            {/* back button */}
            <button
              type="button"
              className="btn back-btn"
              onClick={() => handlePrevStep(values)}
            >
              Back
            </button>
            {/* next button */}
            <button className="btn next-btn" type="submit">
              Next
            </button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default FormThree
