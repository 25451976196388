import { Field } from "formik"
import React from "react"

const Dropdown = ({ title, name, id, selection, isRequired }) => {
  return (
    <div className="row">
      <div className="dwelling-container">
        <label htmlFor={name} className="label">
          <h3 className="dropdown-title">{title}</h3>
        </label>

        <Field name={`${name}`} id={id} as="select" required>
          {selection.includes("none") ? null : (
            <option value="">Choose option</option>
          )}

          {selection.map((item, index) => {
            return (
              <option key={index} value={item}>
                {item}
              </option>
            )
          })}
        </Field>
      </div>
    </div>
  )
}

export default Dropdown
