import React from "react"
import { useAutoContext } from "../../../context/auto_context"
import Dropdown from "../AllDropdown"

const BodilyInjury = () => {
  const { allData } = useAutoContext()
  const bodilyInjury = allData.filter(
    item => item.fields.name === "bodilyInjury"
  )[0]

  return (
    <>
      <Dropdown
        title={bodilyInjury.fields.title}
        name={bodilyInjury.fields.name}
        key={bodilyInjury.sys.id}
        id={bodilyInjury.sys.id}
        type={bodilyInjury.fields.type}
        isRequired={bodilyInjury.fields.isRequired}
        selection={[...bodilyInjury.fields.selection]}
      />
    </>
  )
}

export default BodilyInjury
