import React from "react"
import { useAutoContext } from "../../../context/auto_context"
import Dropdown from "../AllDropdown"

const UninsuredMotorist = () => {
  const { allData } = useAutoContext()
  const uninsuredMotorist = allData.filter(
    item => item.fields.name === "uninsuredMotorist"
  )[0]

  return (
    <>
      <Dropdown
        title={uninsuredMotorist.fields.title}
        name={uninsuredMotorist.fields.name}
        key={uninsuredMotorist.sys.id}
        id={uninsuredMotorist.sys.id}
        type={uninsuredMotorist.fields.type}
        isRequired={uninsuredMotorist.fields.isRequired}
        selection={[...uninsuredMotorist.fields.selection]}
      />
    </>
  )
}

export default UninsuredMotorist
